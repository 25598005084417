.home {
  background-color: #205b89;
  background-image: url("../../resources/images/mission-background.jpg");
  background-repeat: no-repeat;
  background-size: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 320px) {
  .home {
    background-image: none;
  }
}
