$blue-color: #2196f3;
$light-gray: #606060;
$ultra-light-gray: rgba(0, 0, 0, 0.15);
$modal-background-color: rgba(0, 0, 0, 0.5);

* {
  box-sizing: border-box;
}

.youtubePlayerContainer {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
}

.youtubePlayer {
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  border: none;
  position: absolute;
}

.section {
  padding-left: 2rem;
  padding-right: 2rem;
}

.blue {
  color: $blue-color;
}

.white {
  color: white;
}

.black {
  color: black;
}

.flexbox {
  display: flex;
}

.divider {
  height: 1px;
  border: 1px dotted $ultra-light-gray;
  width: 100%;
}

.flexibleSpace {
  flex-grow: 1;
}

/* Title types */
.title {
  display: flex;
  font-weight: bold;
}

.titleSectionPadding {
  padding-top: 5rem;
}

.titleReducedPadding {
  padding-top: 1rem;
}

.titleBig {
  font-size: xxx-large;
}

.titleSmall {
  font-size: x-large;
}

.sectionTitle {
  @extend .title, .titleBig, .titleSectionPadding;
}

.sectionTitleReducedPadding {
  @extend .title, .titleBig, .titleReducedPadding;
}

.sectionTitleSmallReducedPadding {
  @extend .title, .titleSmall, .titleReducedPadding;
}

.siteFooter {
  text-align: center;

  padding-top: 2rem;
  padding-bottom: 2rem;
}

.text {
  display: flex;
}

/* Navigation bars */
.commonNavbar {
  top: 0;
  z-index: 1;
  width: 100%;
  height: 64px;
  display: flex;
  position: fixed;
  overflow: hidden;
  align-items: center;
  padding: 0 2rem 0 2rem;
  box-shadow: 0px 0px 2px 1px $light-gray;
}

.navbar {
  background-color: white;
  color: $blue-color;

  @extend .commonNavbar;
}

.navbarModal {
  background-color: $blue-color;
  color: white;

  top: 25%;
  left: 25%;
  width: 50%;

  border-radius: 5px 5px 0px 0px;

  @extend .commonNavbar;
}

.navbarText {
  padding: 0rem 1rem 0rem 1rem;
}

.navBarTitle {
  @extend .navbarText;
}

.navbarModalText {
  padding-right: 1em;
}

.modalBoxContainer {
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: $modal-background-color;
}

.modalBox {
  top: 25%;
  left: 25%;
  z-index: 2;
  width: 50%;
  height: 50%;
  position: fixed;
  overflow-y: scroll;
  background-color: white;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0px 0px 30px 5px $modal-background-color;
}

.navbarMenuItem {
  display: flex;
  padding: 1rem;
}
.navbarMenuItemHint {
  display: flex;
  padding: 0 1rem 1rem 1rem;
  font-style: italic;
  font-size: smaller;
}

.navbarMenuSection {
  display: flex;
  color: white;
  padding: 0.25rem;
  font-size: medium;
  background-color: $blue-color;
}

.navbarMainMenu {
  top: 0;
  right: 0;
  z-index: 2;
  width: 320px;
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  background-color: white;
  box-shadow: 0px 0px 30px 5px $modal-background-color;
}

/* Links */
a.navButton,
a.navbarMenuItem,
div.navbarMenuItem {
  color: black;
  cursor: pointer;
  text-decoration: none;
}

a.navButton:hover,
a.navbarMenuItem:hover,
div.navbarMenuItem:hover {
  color: black;
  cursor: pointer;
  text-decoration: underline !important;
}

a.navButton:disabled,
a.navbarMenuItem:disabled,
div.navbarMenuItem:disabled {
  cursor: pointer;
  color: $light-gray;
  text-decoration: none;
}

.navBarButtonContainer {
  padding-left: 1em;
}

.navModalCloseButton {
  width: 100%;
  padding: 1rem;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  color: $blue-color;
  text-align: center;
  text-decoration: none;
}

.notFoundBackToHome {
  padding: 0 !important;
  margin-left: 0 !important;
  text-align: left !important;

  @extend .navModalCloseButton;
}

.navModalCloseButton:hover {
  color: $blue-color;
  text-decoration: underline !important;
}

.navModalCloseButton:disabled {
  color: $light-gray;
  text-decoration: none;
}

.scrollForMore {
  width: 100%;
  text-align: center;
  font-weight: bold;
  padding-bottom: 1rem;
}

.arrowDown {
  display: inline-block;
  border-right: 4px solid white;
  border-bottom: 4px solid white;
  width: 10px;
  height: 10px;
  transform: rotate(-315deg);
}

.infoBoxContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.infoBoxTitle {
  font-weight: bold;
  font-size: large;
}

/* infoBox1 is used for rows with only one element */
.infoBox1 {
  width: 100%;
  margin-bottom: 2rem;
}

.infoBoxContentA1 {
  margin: 1rem 0 1rem 0;
}

/* infoBox2 is used for rows that may contain up to 2 elements */
.infoBox2 {
  width: 100%;
  margin-bottom: 2rem;
}

.infoBoxContentA2 {
  margin: 1rem 0 1rem 0;
}

.infoBoxContentB2 {
  margin: 1rem 0 1rem 0;
}

/* infoBox3 is used for rows that may contain up to 3 elements */
.infoBox3 {
  width: 100%;
  margin-bottom: 2rem;
}

.infoBoxContentA3 {
  margin: 1rem 0 1rem 0;
}

.infoBoxContentB3 {
  margin: 1rem 0 1rem 0;
}

.infoBoxContentC3 {
  margin: 1rem 0 1rem 0;
}

/* infoBox4 is used for rows that may contain up to 4 elements */
.infoBox4 {
  width: 100%;
  margin-bottom: 2rem;
}

.infoBoxContentA4 {
  margin: 1rem 0 1rem 0;
}

.infoBoxContentB4 {
  margin: 1rem 0 1rem 0;
}

.infoBoxContentC4 {
  margin: 1rem 0 1rem 0;
}

.infoBoxContentD4 {
  margin: 1rem 0 1rem 0;
}

.infoBoxContent {
  border: 1px solid $blue-color;
  border-radius: 5px;
  text-align: center;
  padding: 1rem;
  height: 100%;
}

.homeText {
  display: flex;
  width: 50%;
}

/* "Home" text adaptive rules  */
@media screen and (max-width: 480px) {
  .homeText {
    width: 100%;
  }
}

@media screen and (min-width: 480px) and (max-width: 640px) {
  .homeText {
    width: 90%;
  }
}

@media screen and (min-width: 640px) and (max-width: 800px) {
  .homeText {
    width: 80%;
  }
}

@media screen and (min-width: 800px) and (max-width: 960px) {
  .homeText {
    width: 70%;
  }
}

@media screen and (min-width: 960px) and (max-width: 1120px) {
  .homeText {
    width: 60%;
  }
}

/* Modal box adaptive rules */
@media screen and (max-width: 480px) {
  .navBarTitle {
    display: none;
  }

  .navbarModal {
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
  }

  .modalBox {
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
  }
}

@media screen and (min-width: 480px) and (max-width: 800px) {
  .navbarModal {
    top: 10% !important;
    left: 10% !important;
    width: 80% !important;
  }

  .modalBox {
    top: 10% !important;
    left: 10% !important;
    width: 80% !important;
    height: 80% !important;
  }
}

@media screen and (min-width: 800px) and (max-width: 960px) {
  .navbarModal {
    top: 12.5% !important;
    left: 12.5% !important;
    width: 75% !important;
  }

  .modalBox {
    top: 12.5% !important;
    left: 12.5% !important;
    width: 75% !important;
    height: 75% !important;
  }
}

/* Info box adaptive rules */
@media screen and (min-width: 480px) and (max-width: 960px) {
  .infoBox4 {
    width: 50%;
  }

  .infoBoxContentA4 {
    margin-right: 1rem;
  }

  .infoBoxContentB4 {
    margin-left: 1rem;
  }

  .infoBoxContentC4 {
    margin-right: 1rem;
  }

  .infoBoxContentD4 {
    margin-left: 1rem;
  }
}

@media screen and (min-width: 960px) {
  .infoBox2 {
    width: 50%;
  }

  .infoBoxContentA2 {
    margin-right: 1rem;
  }

  .infoBoxContentB2 {
    margin-left: 1rem;
  }

  .infoBox3 {
    width: 33.3%;
  }

  .infoBoxContentA3 {
    margin-right: 1rem;
  }

  .infoBoxContentB3 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .infoBoxContentC3 {
    margin-left: 1rem;
  }

  .infoBox4 {
    width: 25%;
  }

  .infoBoxContentA4 {
    margin-right: 1rem;
  }

  .infoBoxContentB4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .infoBoxContentC4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .infoBoxContentD4 {
    margin-left: 1rem;
  }
}
