$blue-color: #2196f3;

.flag {
  width: 24px;
  height: 24px;
  background-size: 100%;
  background-repeat: no-repeat;
}

.flagSelected {
  border-bottom: 1px solid $blue-color;
}

.flag_it {
  background-image: image-set(
    url("../resources/images/flag-it.png") 1x,
    url("../resources/images/flag-it@2x.png") 2x,
    url("../resources/images/flag-it@3x.png") 3x
  );

  @extend .flag, .flagSelected;
}

.flag_en {
  background-image: image-set(
    url("../resources/images/flag-en.png") 1x,
    url("../resources/images/flag-en@2x.png") 2x,
    url("../resources/images/flag-en@3x.png") 3x
  );

  @extend .flag, .flagSelected;
}

.flagClickable_it {
  cursor: pointer;
  border-bottom: none !important;

  @extend .flag_it;
}

.flagClickable_en {
  cursor: pointer;
  border-bottom: none !important;

  @extend .flag_en;
}
