.roadmap {
  background-color: #15324a;
  background-image: url("../../resources/images/roadmap-background.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  min-height: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.gitIndicator {
  width: 32px;
  min-height: 32px;
  background-size: 100%;
  background-repeat: no-repeat;
}

.gitStart {
  background-image: image-set(
    url("../../resources/images/git-start.png") 1x,
    url("../../resources/images/git-start@2x.png") 2x,
    url("../../resources/images/git-start@3x.png") 3x
  );

  @extend .gitIndicator;
}

.gitBranch {
  background-image: image-set(
    url("../../resources/images/git-branch.png") 1x,
    url("../../resources/images/git-branch@2x.png") 2x,
    url("../../resources/images/git-branch@3x.png") 3x
  );

  @extend .gitIndicator;
}

.gitMasterContinue {
  background-image: image-set(
    url("../../resources/images/git-master-continue.png") 1x,
    url("../../resources/images/git-master-continue@2x.png") 2x,
    url("../../resources/images/git-master-continue@3x.png") 3x
  );

  @extend .gitIndicator;
}

.gitBranchContinue {
  flex-direction: column;
  background-image: image-set(
    url("../../resources/images/git-branch-continue.png") 1x,
    url("../../resources/images/git-branch-continue@2x.png") 2x,
    url("../../resources/images/git-branch-continue@3x.png") 3x
  );

  @extend .gitIndicator;
}

.gitFeature {
  width: 100%;
  background-size: 32px;
  padding: 0 0 0 40px;
  background-repeat: repeat-y !important;
  background-image: image-set(
    url("../../resources/images/git-continue.png") 1x,
    url("../../resources/images/git-continue@2x.png") 2x,
    url("../../resources/images/git-continue@3x.png") 3x
  );

  @extend .gitIndicator;
}

.roadmapFeatureTitle {
  font-weight: bold;
  font-size: large;
}

.roadmapFeatureTarget {
  font-weight: bold;
}

.roadmapFeatureSeparator {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed white;
}
