.arrowRight {
  display: inline-block;
  border-right: 4px solid;
  border-bottom: 4px solid;
  width: 16px;
  height: 16px;
  transform: rotate(-45deg);
}

.arrowLeft {
  display: inline-block;
  border-right: 4px solid;
  border-bottom: 4px solid;
  width: 16px;
  height: 16px;
  transform: rotate(-225deg);
}

.blogNavigator {
  display: flex;
  margin-top: 3rem;
}

.blogNavigatorButtonContainer {
  display: flex;
  align-items: center;
}

.blogNavigatorButton {
  display: flex;
  border: none;
  background: none;
  font-size: medium;
}

.blogNavigatorSpace {
  display: flex;
  flex-grow: 1;
}

.blogPostContainer {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.blogPostAuthor {
  padding-top: 2rem;
  text-align: right;
}

/* "Home" text adaptive rules  */
@media screen and (max-width: 640px) {
  .blogPostContainer {
    width: 100%;
  }
}

@media screen and (min-width: 640px) and (max-width: 960px) {
  .blogPostContainer {
    width: 90%;
  }
}

@media screen and (min-width: 960px) {
  .blogPostContainer {
    width: 75%;
  }
}
