$blue-color: #2196f3;
$light-gray: rgba(0, 0, 0, 0.15);

.featuresTableHeaderContainer {
  display: flex;
  padding: 1rem 0 0 0;
}

.featuresTableHeader {
  border-radius: 5px 5px 0px 0px;
  background: $blue-color;
  color: white;
  font-weight: bold;
  width: 100%;
}

.featureRowContainer {
  display: flex;
  padding: 0 0 0 0;
  cursor: pointer;
}

.featureRow {
  width: 100%;
  height: 100%;
  border-left: 1px solid $light-gray;
  border-right: 1px solid $light-gray;
  border-bottom: 1px solid $light-gray;
}

.featureRowLast {
  border-radius: 0px 0px 5px 5px;

  @extend .featureRow;
}

.featureColumn {
  width: 100%;
  float: left;
  padding: 1rem 2rem 1rem 2rem;
}

.featureName {
  text-align: center;
  display: table-cell;

  @extend .featureColumn;
}

.platforms {
  display: flex;
  justify-content: flex-end;
}
.platformIconPadded {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.platformsModal {
  display: flex;
}

.featurePlatform {
  text-align: right;
  display: none;

  @extend .featureColumn;
}

.featureLimitations {
  text-align: right;
  display: none;

  @extend .featureColumn;
}

@media screen and (min-width: 480px) and (max-width: 960px) {
  .featureName {
    width: 50%;
    text-align: left !important;

    @extend .featureColumn;
  }

  .featurePlatform {
    width: 50%;
    text-align: right !important;
    display: table-cell;

    @extend .featureColumn;
  }
}

@media screen and (min-width: 960px) {
  .featureName {
    width: 33.3%;
    text-align: left !important;
  }

  .featurePlatform {
    width: 33.3%;
    text-align: center !important;
    display: table-cell;
  }

  .platforms {
    justify-content: center;
  }

  .featureLimitations {
    width: 33.3%;
    text-align: right !important;
    display: table-cell;
  }
}
